@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background: #fafafa;
}

.sidebar-hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .sidebar-hide-scrollbar {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-track {
    background: #000000; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #357ace; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  


  /* Bubble animation styles */
.bubble {
  position: absolute;
  bottom: -100px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  filter: blur(8px);
  animation: floatUp 10s infinite ease-in-out;
}

.bubble1 {
  left: 10%;
  width: 60px;
  height: 60px;
  animation-duration: 12s;
}

.bubble2 {
  left: 50%;
  width: 80px;
  height: 80px;
  animation-duration: 14s;
}

.bubble3 {
  left: 80%;
  width: 100px;
  height: 100px;
  animation-duration: 18s;
}

@keyframes floatUp {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}
.transparent-shadow {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5); 
}

